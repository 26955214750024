/********
 * NOTE: These variables and mixins MUST be in this file so that this file can be used
 *       on its own for use in AEM dialog.
 ********/
/* Flex Mixin */
/*************************
 * For backwards compatibility of previously authored grids, the key and value pair
 * should not be changed. New values will have the key out of order if the values
 * are ordered from smallest to largest.
 *************************/
/* Flex Mixin */
/* Flex Mixin */
/*************************
 * For backwards compatibility of previously authored grids, the key and value pair
 * should not be changed. New values will have the key out of order if the values
 * are ordered from smallest to largest.
 *************************/
/**
 * Shorthand mixin for absolute positioning
 *
 * @param {Length} $vertical [top] - Vertical position
 * @param {Length} $horizontal [null] - Horizontal position
 */
/**
 * Vertically align content properly
 */
/*Primary Nav Link border, e.g bottom*/
/* Draw an arrow pointing straight up, down, left and right */
/* Draw a triangle pointing straight up, down, left and right */
/* Required for use within @media */
/* $medium-up etc variables are not available in _variables.scss
   hence for now, we are declaring a map as a means to acess
   the media variables dynamically.
*/
.q-multimedia-teaser [class*=q-video-caption] {
  display: none !important;
}

.q-multimedia-teaser .q-teaser-appearance-b-text .q-teaser-headline-text-b {
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 0.375rem;
  padding-top: 0rem;
  color: #262626;
  font-family: Louis, Tahoma, NotoSansKR, NanumGothic, sans-serif;
  font-style: inherit;
  font-weight: 400;
  letter-spacing: 0.15rem;
  margin: 0;
  padding: 0;
}
@media print, only screen and (min-width:600px) {
  .q-multimedia-teaser .q-teaser-appearance-b-text .q-teaser-headline-text-b {
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 0.375rem;
    padding-top: 0rem;
  }
}
@media only screen and (min-width:1500px) {
  .q-multimedia-teaser .q-teaser-appearance-b-text .q-teaser-headline-text-b {
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 0.375rem;
    padding-top: 0rem;
  }
}
.q-multimedia-teaser .q-teaser-appearance-b-text .q-teaser-headline-text-b a {
  color: #262626;
  font-weight: inherit;
  text-decoration: none;
}
.q-multimedia-teaser .q-teaser-appearance-b-text .q-teaser-headline-text-b a:visited {
  color: #262626;
}
.q-multimedia-teaser .q-teaser-appearance-b-text .q-teaser-headline-text-b a:hover, .q-multimedia-teaser .q-teaser-appearance-b-text .q-teaser-headline-text-b a:active {
  color: #cd9834;
}
.q-multimedia-teaser .q-teaser-appearance-b-text .q-invert.q-teaser-headline-text-b {
  color: #ffffff;
}
.q-multimedia-teaser .q-teaser-appearance-b-text .q-invert.q-teaser-headline-text-b a {
  color: inherit;
  font-weight: inherit;
  text-decoration: none;
}
.q-multimedia-teaser .q-teaser-appearance-b-text .q-invert.q-teaser-headline-text-b a:visited {
  color: #ffffff;
}
.q-multimedia-teaser .q-teaser-appearance-b-text .q-invert.q-teaser-headline-text-b a:hover, .q-multimedia-teaser .q-teaser-appearance-b-text .q-invert.q-teaser-headline-text-b a:active {
  color: #e6ac39;
}
.q-multimedia-teaser .q-teaser-appearance-a-text .q-teaser-headline-text-a {
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 0.375rem;
  padding-top: 0rem;
  color: #262626;
  font-family: Louis, Tahoma, NotoSansKR, NanumGothic, sans-serif;
  font-style: inherit;
  font-weight: 400;
  letter-spacing: 0.04rem;
  margin: 0;
  padding: 0;
}
@media print, only screen and (min-width:600px) {
  .q-multimedia-teaser .q-teaser-appearance-a-text .q-teaser-headline-text-a {
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 0.375rem;
    padding-top: 0rem;
  }
}
@media only screen and (min-width:1500px) {
  .q-multimedia-teaser .q-teaser-appearance-a-text .q-teaser-headline-text-a {
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 0.375rem;
    padding-top: 0rem;
  }
}
.q-multimedia-teaser .q-teaser-appearance-a-text .q-teaser-headline-text-a a {
  color: #262626;
  font-weight: inherit;
  text-decoration: none;
}
.q-multimedia-teaser .q-teaser-appearance-a-text .q-teaser-headline-text-a a:visited {
  color: #262626;
}
.q-multimedia-teaser .q-teaser-appearance-a-text .q-teaser-headline-text-a a:hover, .q-multimedia-teaser .q-teaser-appearance-a-text .q-teaser-headline-text-a a:active {
  color: #cd9834;
}
.q-multimedia-teaser .q-teaser-appearance-a-text .q-invert.q-teaser-headline-text-a {
  color: #ffffff;
}
.q-multimedia-teaser .q-teaser-appearance-a-text .q-invert.q-teaser-headline-text-a a {
  color: inherit;
  font-weight: inherit;
  text-decoration: none;
}
.q-multimedia-teaser .q-teaser-appearance-a-text .q-invert.q-teaser-headline-text-a a:visited {
  color: #ffffff;
}
.q-multimedia-teaser .q-teaser-appearance-a-text .q-invert.q-teaser-headline-text-a a:hover, .q-multimedia-teaser .q-teaser-appearance-a-text .q-invert.q-teaser-headline-text-a a:active {
  color: #e6ac39;
}
.q-multimedia-teaser .q-teaser-appearance-a-text .q-heavy.q-teaser-headline-text-a {
  font-weight: 900;
}

.q-body3, .q-multimedia-teaser .q-teaser-appearance-b-text .q-teaser-copy-text-b, .q-multimedia-teaser .q-teaser-appearance-a-text .q-teaser-copy-text-a {
  font-family: Roboto, Helvetica, NotoSansKR, NanumGothic, sans-serif;
}
.q-body3 a, .q-multimedia-teaser .q-teaser-appearance-b-text .q-teaser-copy-text-b a, .q-multimedia-teaser .q-teaser-appearance-a-text .q-teaser-copy-text-a a {
  color: inherit;
  font-weight: 500;
  text-decoration: underline;
}
.q-body3 a:visited, .q-multimedia-teaser .q-teaser-appearance-b-text .q-teaser-copy-text-b a:visited, .q-multimedia-teaser .q-teaser-appearance-a-text .q-teaser-copy-text-a a:visited {
  color: #262626;
}
.q-body3 a:hover, .q-multimedia-teaser .q-teaser-appearance-b-text .q-teaser-copy-text-b a:hover, .q-multimedia-teaser .q-teaser-appearance-a-text .q-teaser-copy-text-a a:hover, .q-body3 a:active, .q-multimedia-teaser .q-teaser-appearance-b-text .q-teaser-copy-text-b a:active, .q-multimedia-teaser .q-teaser-appearance-a-text .q-teaser-copy-text-a a:active {
  color: #cd9834;
}
.q-body3 ul li, .q-multimedia-teaser .q-teaser-appearance-b-text .q-teaser-copy-text-b ul li, .q-multimedia-teaser .q-teaser-appearance-a-text .q-teaser-copy-text-a ul li,
.q-body3 ol li,
.q-multimedia-teaser .q-teaser-appearance-b-text .q-teaser-copy-text-b ol li,
.q-multimedia-teaser .q-teaser-appearance-a-text .q-teaser-copy-text-a ol li {
  margin-top: 0;
}
.q-invert.q-body3, .q-multimedia-teaser .q-teaser-appearance-b-text .q-invert.q-teaser-copy-text-b, .q-multimedia-teaser .q-teaser-appearance-a-text .q-invert.q-teaser-copy-text-a {
  color: #ffffff;
}
.q-invert.q-body3 a, .q-multimedia-teaser .q-teaser-appearance-b-text .q-invert.q-teaser-copy-text-b a, .q-multimedia-teaser .q-teaser-appearance-a-text .q-invert.q-teaser-copy-text-a a {
  color: inherit;
  font-weight: inherit;
  text-decoration: underline;
}
.q-invert.q-body3 a:visited, .q-multimedia-teaser .q-teaser-appearance-b-text .q-invert.q-teaser-copy-text-b a:visited, .q-multimedia-teaser .q-teaser-appearance-a-text .q-invert.q-teaser-copy-text-a a:visited {
  color: #ffffff;
}
.q-invert.q-body3 a:hover, .q-multimedia-teaser .q-teaser-appearance-b-text .q-invert.q-teaser-copy-text-b a:hover, .q-multimedia-teaser .q-teaser-appearance-a-text .q-invert.q-teaser-copy-text-a a:hover, .q-invert.q-body3 a:active, .q-multimedia-teaser .q-teaser-appearance-b-text .q-invert.q-teaser-copy-text-b a:active, .q-multimedia-teaser .q-teaser-appearance-a-text .q-invert.q-teaser-copy-text-a a:active {
  color: #e6ac39;
}

.q-multimedia-teaser .q-teaser-appearance-b-text .q-teaser-copy-text-b, .q-multimedia-teaser .q-teaser-appearance-a-text .q-teaser-copy-text-a {
  font-size: 0.8rem;
  line-height: 1.25rem;
  margin-bottom: 0.625rem;
  padding-top: 0rem;
  color: #808080;
}
@media print, only screen and (min-width:600px) {
  .q-multimedia-teaser .q-teaser-appearance-b-text .q-teaser-copy-text-b, .q-multimedia-teaser .q-teaser-appearance-a-text .q-teaser-copy-text-a {
    font-size: 0.8rem;
    line-height: 1.25rem;
    margin-bottom: 0.625rem;
    padding-top: 0rem;
  }
}
@media only screen and (min-width:1500px) {
  .q-multimedia-teaser .q-teaser-appearance-b-text .q-teaser-copy-text-b, .q-multimedia-teaser .q-teaser-appearance-a-text .q-teaser-copy-text-a {
    font-size: 0.8rem;
    line-height: 1.25rem;
    margin-bottom: 0.625rem;
    padding-top: 0rem;
  }
}
.q-multimedia-teaser .q-teaser-appearance-b-text .q-teaser-copy-text-b ul, .q-multimedia-teaser .q-teaser-appearance-a-text .q-teaser-copy-text-a ul,
.q-multimedia-teaser .q-teaser-appearance-b-text .q-teaser-copy-text-b ol,
.q-multimedia-teaser .q-teaser-appearance-a-text .q-teaser-copy-text-a ol {
  margin: 0 0 0 1.25rem;
}

.q-multimedia-teaser .q-teaser-appearance-b-text .q-teaser-copy-text-b, .q-multimedia-teaser .q-teaser-appearance-a-text .q-teaser-copy-text-a {
  color: #6e6e6e;
}
.q-multimedia-teaser .q-teaser-appearance-b-text .q-teaser-copy-text-b a, .q-multimedia-teaser .q-teaser-appearance-a-text .q-teaser-copy-text-a a {
  font-weight: 700;
}
@media only screen and (max-width: 419px) {
  .q-multimedia-teaser .q-teaser-appearance-b-text .q-teaser-copy-text-b, .q-multimedia-teaser .q-teaser-appearance-a-text .q-teaser-copy-text-a {
    color: #262626;
  }
}

.q-headline1,
.q-headline2,
.q-headline3,
.q-descriptive1,
.q-descriptive2 {
  margin-bottom: 0;
  margin-top: 0;
}

.q-descriptive-section {
  font-size: 0.866rem;
  line-height: 1.333rem;
  margin-bottom: 0.542rem;
  padding-top: 0rem;
  color: #6e6e6e;
  font-family: Louis, Tahoma, NotoSansKR, NanumGothic, sans-serif;
  font-style: inherit;
  font-weight: 700;
  letter-spacing: 0.3rem;
  margin: 0;
  padding: 0;
  margin-bottom: 0;
}
@media print, only screen and (min-width:600px) {
  .q-descriptive-section {
    font-size: 0.866rem;
    line-height: 1.333rem;
    margin-bottom: 0.542rem;
    padding-top: 0rem;
  }
}
@media only screen and (min-width:1500px) {
  .q-descriptive-section {
    font-size: 0.866rem;
    line-height: 1.333rem;
    margin-bottom: 0.542rem;
    padding-top: 0rem;
  }
}
.q-descriptive-section a {
  color: #6e6e6e;
  font-weight: inherit;
  text-decoration: none;
}
.q-descriptive-section a:visited {
  color: #262626;
}
.q-descriptive-section a:hover, .q-descriptive-section a:active {
  color: #cd9834;
}
.q-descriptive-section.q-invert {
  color: #ffffff;
}
.q-descriptive-section.q-invert a {
  color: inherit;
  font-weight: inherit;
  text-decoration: none;
}
.q-descriptive-section.q-invert a:visited {
  color: #ffffff;
}
.q-descriptive-section.q-invert a:hover, .q-descriptive-section.q-invert a:active {
  color: #e6ac39;
}
.q-retail-body {
  font-size: 0.75rem;
  line-height: 1rem;
  margin-bottom: 0.25rem;
  padding-top: 0rem;
  color: #262626;
  font-family: Louis, Tahoma, NotoSansKR, NanumGothic, sans-serif;
  font-style: normal;
  font-weight: 700;
  margin: 0;
  padding: 0;
}
@media print, only screen and (min-width:600px) {
  .q-retail-body {
    font-size: 0.75rem;
    line-height: 1rem;
    margin-bottom: 0.25rem;
    padding-top: 0rem;
  }
}
@media only screen and (min-width:1500px) {
  .q-retail-body {
    font-size: 0.75rem;
    line-height: 1rem;
    margin-bottom: 0.25rem;
    padding-top: 0rem;
  }
}
.q-retail-body a {
  color: #262626;
  font-weight: inherit;
  text-decoration: none;
}
.q-retail-body a:visited {
  color: #262626;
}
.q-retail-body a:hover, .q-retail-body a:active {
  color: #cd9834;
}
.q-retail-body.q-invert {
  color: #ffffff;
}
.q-retail-body.q-invert a {
  color: inherit;
  font-weight: inherit;
  text-decoration: none;
}
.q-retail-body.q-invert a:visited {
  color: #ffffff;
}
.q-retail-body.q-invert a:hover, .q-retail-body.q-invert a:active {
  color: #e6ac39;
}
.q-retail-label {
  font-size: 1rem;
  line-height: 1.3rem;
  margin-bottom: 0.575rem;
  padding-top: 0rem;
  color: #262626;
  font-family: Louis, Tahoma, NotoSansKR, NanumGothic, sans-serif;
  font-style: normal;
  font-weight: 700;
  margin: 0;
  padding: 0;
}
@media print, only screen and (min-width:600px) {
  .q-retail-label {
    font-size: 1rem;
    line-height: 1.3rem;
    margin-bottom: 0.575rem;
    padding-top: 0rem;
  }
}
@media only screen and (min-width:1500px) {
  .q-retail-label {
    font-size: 1rem;
    line-height: 1.3rem;
    margin-bottom: 0.575rem;
    padding-top: 0rem;
  }
}
.q-retail-label a {
  color: #262626;
  font-weight: inherit;
  text-decoration: none;
}
.q-retail-label a:visited {
  color: #262626;
}
.q-retail-label a:hover, .q-retail-label a:active {
  color: #cd9834;
}
.q-retail-label.q-invert {
  color: #ffffff;
}
.q-retail-label.q-invert a {
  color: inherit;
  font-weight: inherit;
  text-decoration: none;
}
.q-retail-label.q-invert a:visited {
  color: #ffffff;
}
.q-retail-label.q-invert a:hover, .q-retail-label.q-invert a:active {
  color: #e6ac39;
}
.q-retail-numeral {
  font-size: 1.75rem;
  line-height: 1.75rem;
  margin-bottom: 0.125rem;
  padding-top: 0rem;
  color: #cd9834;
  font-family: Louis, Tahoma, NotoSansKR, NanumGothic, sans-serif;
  font-style: normal;
  font-weight: 700;
  margin: 0;
  padding: 0;
}
@media print, only screen and (min-width:600px) {
  .q-retail-numeral {
    font-size: 1.75rem;
    line-height: 1.75rem;
    margin-bottom: 0.125rem;
    padding-top: 0rem;
  }
}
@media only screen and (min-width:1500px) {
  .q-retail-numeral {
    font-size: 1.75rem;
    line-height: 1.75rem;
    margin-bottom: 0.125rem;
    padding-top: 0rem;
  }
}
.q-retail-numeral a {
  color: #cd9834;
  font-weight: inherit;
  text-decoration: none;
}
.q-retail-numeral a:visited {
  color: #262626;
}
.q-retail-numeral a:hover, .q-retail-numeral a:active {
  color: #cd9834;
}
.q-retail-numeral.q-invert {
  color: #ffffff;
}
.q-retail-numeral.q-invert a {
  color: inherit;
  font-weight: inherit;
  text-decoration: none;
}
.q-retail-numeral.q-invert a:visited {
  color: #ffffff;
}
.q-retail-numeral.q-invert a:hover, .q-retail-numeral.q-invert a:active {
  color: #e6ac39;
}
.q-body3 {
  font-size: 0.8rem;
  line-height: 1rem;
  margin-bottom: 0.25rem;
  padding-top: 0rem;
  color: #6e6e6e;
}
@media print, only screen and (min-width:600px) {
  .q-body3 {
    font-size: 0.7rem;
    line-height: 0.75rem;
    margin-bottom: 0.5rem;
    padding-top: 0rem;
  }
}
@media only screen and (min-width:1500px) {
  .q-body3 {
    font-size: 0.7rem;
    line-height: 0.75rem;
    margin-bottom: 0.5rem;
    padding-top: 0rem;
  }
}
@media only screen {
  .q-body3 {
    margin-bottom: 1rem;
  }
}
.q-body3 ul,
.q-body3 ol {
  margin: 0 0 0 1.25rem;
}

/* Flex Mixin */
.q-multimedia-teaser .q-teaser-appearance-a-text .q-teaser-longer-text,
.q-multimedia-teaser .q-teaser-appearance-b-text .q-teaser-longer-text {
  display: none;
}
.q-multimedia-teaser .q-teaser-appearance-a-text .q-teaser-text-title,
.q-multimedia-teaser .q-teaser-appearance-b-text .q-teaser-text-title {
  padding: 1.875rem 0 1.25rem 0;
}
.q-multimedia-teaser .q-teaser-appearance-a-text .q-teaser-text-description,
.q-multimedia-teaser .q-teaser-appearance-b-text .q-teaser-text-description {
  color: #262626;
  margin: 0;
  padding: 0.1875rem 0 1.25rem 0;
}

.q-multimedia-teaser {
  cursor: pointer;
  margin-bottom: 1.5rem;
}
.q-multimedia-teaser .q-teaser-appearance-full-panel {
  padding: 0 0 0.9375rem 0;
}
.q-multimedia-teaser .q-teaser-separator-light {
  background-color: #b3b3b3;
  border: 0;
  display: block;
  height: 0.0625rem;
  margin: 0;
  width: 100%;
}
.q-multimedia-teaser .q-style-b-container {
  padding: 1.25rem 5%;
}
.q-multimedia-teaser .q-style-b-container .q-teaser-media {
  padding: 0;
}
.q-multimedia-teaser .q-style-b-container .q-teaser-text-title {
  padding: 0;
}
.q-multimedia-teaser .q-style-b-container .q-teaser-text-description {
  padding: 0;
}
@media print, only screen and (min-width:600px) {
  .q-multimedia-teaser .q-teaser-appearance-a-text .q-teaser-longer-text,
.q-multimedia-teaser .q-teaser-appearance-b-text .q-teaser-longer-text {
    display: block;
  }
  .q-multimedia-teaser .q-teaser-appearance-a-text .q-teaser-shorter-text,
.q-multimedia-teaser .q-teaser-appearance-b-text .q-teaser-shorter-text {
    display: none;
  }
  .q-multimedia-teaser .q-teaser-appearance-a-text .q-teaser-text-title,
.q-multimedia-teaser .q-teaser-appearance-b-text .q-teaser-text-title {
    padding: 1.875rem 0 1.25rem 0;
  }
  .q-multimedia-teaser .q-teaser-appearance-a-text .q-teaser-text-description,
.q-multimedia-teaser .q-teaser-appearance-b-text .q-teaser-text-description {
    color: #808080;
    padding: 0.1875rem 0 1.25rem 0;
  }
  .q-multimedia-teaser .q-style-b-container {
    padding: 0 1.25rem 0 0;
  }
  .q-multimedia-teaser .q-style-b-container .q-teaser-media {
    padding: 0 0 0.9375rem 0;
  }
  .q-multimedia-teaser .q-style-b-container .q-teaser-text-title {
    padding: 0;
  }
  .q-multimedia-teaser .q-style-b-container .q-teaser-text-description {
    padding: 0 0 1.25rem 0;
  }
  .q-multimedia-teaser .q-style-b-container .q-teaser-headline-text-b {
    margin: 0;
  }
  .q-multimedia-teaser .q-style-b-container .q-teaser-copy-text-b {
    margin: 0;
  }
}

.q-multimedia-teaser.q-normal-hover {
  position: relative;
}
.q-multimedia-teaser.q-normal-hover:hover::after {
  border-color: #cd9834;
}
.q-multimedia-teaser.q-normal-hover::after {
  border-style: solid;
  content: "";
}
.q-multimedia-teaser.q-normal-hover::after {
  border-style: none;
  content: none;
}
.q-multimedia-teaser.q-style-b-hover {
  position: relative;
  padding-bottom: 0;
  position: relative;
}
.q-multimedia-teaser.q-style-b-hover:hover::after {
  border-color: #cd9834;
}
.q-multimedia-teaser.q-style-b-hover::after {
  border-style: solid;
  content: "";
}
.q-multimedia-teaser.q-style-b-hover:hover::after {
  bottom: 0;
  height: 100%;
  left: 0;
  width: 0;
}
.q-multimedia-teaser.q-style-b-hover::after {
  border-color: #ffffff;
  border-width: 0 0 0 0.1875rem;
  bottom: 0;
  left: 0;
  position: absolute;
  width: 0;
}
.q-multimedia-teaser.q-style-b-hover {
  margin-left: -5vw;
  margin-right: -5vw;
}
.q-multimedia-teaser .q-style-b-container {
  border-bottom: 0.0625rem solid #b3b3b3;
  border-top: 0.0625rem solid #b3b3b3;
}
.q-multimedia-teaser .q-style-b-container .q-style-b-media {
  display: table-cell;
}
.q-multimedia-teaser .q-style-b-container .q-teaser-appearance-b-text {
  display: table-cell;
  padding-left: 0.625rem;
}
@media print, only screen and (min-width:600px) {
  .q-multimedia-teaser.q-normal-hover:hover::after {
    bottom: 0;
    height: 0;
    left: 0;
    width: 100%;
  }
  .q-multimedia-teaser.q-normal-hover::after {
    border-color: #ffffff;
    border-style: solid;
    border-width: 0 0 0.1875rem;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    width: 100%;
  }
  .q-multimedia-teaser.q-style-b-hover {
    margin-left: 0;
    margin-right: 0;
  }
  .q-multimedia-teaser .q-style-b-container {
    border-bottom: 0;
    border-top: 0;
    display: block;
  }
  .q-multimedia-teaser .q-style-b-container .q-style-b-media,
.q-multimedia-teaser .q-style-b-container .q-teaser-appearance-b-text {
    display: block;
    padding: 0;
    width: 100%;
  }
  .q-multimedia-teaser.q-style-b-hover::after {
    border-color: #cd9834;
    border-width: 0 0 0.1875rem;
    left: 50%;
    transition: width 0.25s ease, left 0.25s ease;
  }
  .q-multimedia-teaser.q-style-b-hover:hover::after {
    height: 0;
    width: 100%;
  }
}