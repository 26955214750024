// Multimedia Teaser
//
// This is the documentation for Multimedia Teaser. Edit multimedia_teaser.scss to update this documentation.
// Styleguide 99.99

%teaser-appearance-base-text {
	// Hide longer text for mobile
	.q-teaser-longer-text {
		display: none;
	}

	// Headline text
	.q-teaser-text-title {
		padding: map-get($teaser-title-font-padding-map, mobile);
	}

	// Copy text
	.q-teaser-text-description {
		color: map-get($teaser-description-font-color-map, mobile);
		margin: $mm-teaser-description-margin;
		padding: map-get($teaser-description-font-padding-map, mobile);
	}
}

.q-multimedia-teaser {
	@extend %hide-video-caption;

	cursor: pointer;
	margin-bottom: $mm-teaser-margin-bottom;

	// Teaser Appearance Full Panel
	.q-teaser-appearance-full-panel {
		padding: $mm-teaser-full-panel-padding;
	}

	.q-teaser-separator-light {
		background-color: $mm-teaser-separator-color;
		border: $mm-teaser-separator-border;
		display: $mm-teaser-separator-display;
		height: $mm-teaser-separator-height;
		margin: $mm-teaser-separator-margin;
		width: $mm-teaser-separator-width;
	}

	// Appearance B styles mobile
	.q-style-b-container {
		padding: map-get($teaser-b-container-padding-map, mobile);

		// Appearance B overrides
		.q-teaser-media {
			padding: map-get($teaser-b-media-padding-map, mobile);
		}

		.q-teaser-text-title {
			padding: map-get($teaser-b-title-padding-map, mobile);
		}

		.q-teaser-text-description {
			padding: map-get($teaser-b-description-padding-map, mobile);

		}
	}

	.q-teaser-appearance-a-text,
	.q-teaser-appearance-b-text {
		@extend %teaser-appearance-base-text;
	}

	// Common Teaser Appearance text mobile values
	@media #{$large-up} {
		.q-teaser-appearance-a-text,
		.q-teaser-appearance-b-text {
			.q-teaser-longer-text {
				display: block;
			}

			// Hide shorter text for desktop
			.q-teaser-shorter-text {
				display: none;
			}

			// Headline text
			.q-teaser-text-title {
				padding: map-get($teaser-title-font-padding-map, desktop);
			}

			// Copy text
			.q-teaser-text-description {
				color: map-get($teaser-description-font-color-map, desktop);
				padding: map-get($teaser-description-font-padding-map, desktop);
			}
		}

		.q-style-b-container {
			padding: map-get($teaser-b-container-padding-map, desktop);

			.q-teaser-media {
				padding: map-get($teaser-b-media-padding-map, desktop);
			}

			.q-teaser-text-title {
				padding: map-get($teaser-b-title-padding-map, desktop);
			}

			.q-teaser-text-description {
				padding: map-get($teaser-b-description-padding-map, desktop);
			}

			.q-teaser-headline-text-b {
				margin: $mm-teaser-headline-b-margin;
			}

			.q-teaser-copy-text-b {
				margin: $mm-teaser-copy-b-margin;
			}
		}
	}
}
