// Border Appearance A and Full Panel mobile
.q-multimedia-teaser {
	&.q-normal-hover {
		@include hover-border($mm-teaser-border-hover-color) {
			// There are no borders on mobile. Button is used
			&::after {
				border-style: none;
				content: none;
			}
		}
	}

	// Border Appearance B mobile
	&.q-style-b-hover {
		@include hover-border($mm-teaser-border-hover-color) {
			padding-bottom: 0;
			position: relative;

			&:hover {
				&::after {
					bottom: 0;
					height: 100%;
					left: 0;
					width: 0;
				}
			}

			&::after {
				border-color: $white;
				border-width: 0 0 0 .1875rem;
				bottom: 0;
				left: 0;
				position: absolute;
				width: 0;
			}
		}
	}

	// Appearance A text
	.q-teaser-appearance-a-text {
		@extend %teaser-appearance-base-text;

		.q-teaser-headline-text-a {
			@extend %q-headline3;
		}

		.q-teaser-copy-text-a {
			@extend %q-body2;
		}
	}

	// Appearance B text
	.q-teaser-appearance-b-text {
		@extend %teaser-appearance-base-text;

		.q-teaser-headline-text-b {
			@extend %q-descriptive1;
		}

		.q-teaser-copy-text-b {
			@extend %q-body2;
		}
	}
}

// Appearance B text mobile
.q-multimedia-teaser {
	&.q-style-b-hover {
		margin-left: -5vw;
		margin-right: -5vw;
	}

	// Appearance B column layout
	.q-style-b-container {
		border-bottom: .0625rem solid $grey-mid;
		border-top: .0625rem solid $grey-mid;

		// Appearance B wrappers
		.q-style-b-media {
			display: table-cell;
		}

		.q-teaser-appearance-b-text {
			display: table-cell;
			padding-left: .625rem;
		}
	}

	// Border Appearance A normal and B desktop
	@media #{$large-up} {
		&.q-normal-hover {
			&:hover {
				&::after {
					bottom: 0;
					height: 0;
					left: 0;
					width: 100%;
				}
			}

			&::after {
				border-color: $white;
				border-style: solid;
				border-width: 0 0 .1875rem;
				bottom: 0;
				content: '';
				left: 0;
				position: absolute;
				right: 0;
				width: 100%;
			}
		}

		&.q-style-b-hover {
			margin-left: 0;
			margin-right: 0;
		}

		.q-style-b-container {
			border-bottom: 0;
			border-top: 0;
			display: block;

			// Appearance B wrappers
			.q-style-b-media,
			.q-teaser-appearance-b-text {
				display: block;
				padding: 0;
				width: 100%;
			}
		}

		// Border Appearance B border
		&.q-style-b-hover {
			&::after {
				border-color: $gold;
				border-width: 0 0 .1875rem;
				left: 50%;
				transition: width #{$anim-fast}s ease, left #{$anim-fast}s ease;
			}

			&:hover {
				&::after {
					height: 0;
					width: 100%;
				}
			}
		}
	}
}
